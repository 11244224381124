<template>
    <div class="edit-photos">
        <div class="photos">
            <div class="grid-col">
                <div
                    @click="onClickPhoto(idx)"
                    class="photo"
                    :class="{
                        border: photo.url,
                        required: idx === 0,
                    }"
                    :key="photo.id"
                    v-for="(photo, idx) in photos"
                >
                    <div v-if="photo.url && !photo.$$deleted" class="img-cover" v-img-cover="photo.url" />
                    <i v-else class="material-icons">add</i>
                    <div class="badges">
                        <div v-if="idx === 0" class="badge-required" v-html="$translate('REQUIRED')" />
                        <div
                            v-if="photo.url && !photo.confirmed"
                            class="badge-checking"
                            v-html="$translate('CHECKING')"
                        />
                    </div>
                </div>
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                accept="image/*"
                @change="onChangePhoto"
            />
        </div>
        <div class="photos-desc" v-html="$translate('PHOTOS_DESC')" />
        <button class="photos-guide-button" @click="onGuideButtonClick">
            <i class="material-icons">photo_camera</i>
            사진 가이드 보기
        </button>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'EditPhotos',
    props: ['value'],
    data: () => ({
        photos: [],
        curPhoto: null,
        deletePhotoIds: [],
        photoChanged: false,
    }),
    watch: {
        photoChanged(newChanged, oldChanged) {
            this.$emit('changed', newChanged)
            this.photoChanged = true
        },
    },
    computed: {
        editable() {
            if (this.curPhoto.position === 0) {
                this.$toast.error('메인 사진은 삭제할 수 없습니다')
                return false
            }

            if (this.photos.filter(p => p.id).length <= 1) {
                this.$toast.error('최소 1장의 유효한 인물 사진이 필요합니다')
                return false
            }

            return true
        },
    },
    methods: {
        init() {
            this.$numArray(6).forEach(idx => this.photos.push(this.value[idx] || { url: null }))
        },
        onClickPhoto(index) {
            this.curPhoto = this.photos[index]

            if (!this.curPhoto.id && !this.curPhoto.url) {
                this.$openGallery()
                // this.$refs.imageUploader.click()
                return
            }

            let buttons = [
                {
                    label: '사진 수정',
                    handler: () => {
                        if (!this.curPhoto.id) {
                            // if (this.$store.getters.device.app_version < '1.2.5') {
                            // this.$refs.imageUploader.click()
                            // } else {
                            this.$openGallery()
                            // }
                            return
                        }

                        if (this.editable) {
                            // if (this.$store.getters.device.app_version < '1.2.5') {
                            // this.$refs.imageUploader.click()
                            // } else {
                            this.$openGallery()
                            // }
                        }
                    },
                },
                {
                    label: '사진 삭제',
                    handler: () => {
                        if (!this.curPhoto.id) {
                            this.removePhoto()
                            return
                        }

                        if (this.editable) {
                            this.removePhoto()
                        }
                    },
                },
                {
                    label: '메인 사진으로 지정',
                    class: '',
                    handler: () => this.setMain(),
                },
            ]
            if (index === 0) buttons = buttons.splice(0, 2)
            else if (!this.curPhoto.confirmed) {
                buttons[2].class = 'inactive'
                buttons[2].handler = null
            }
            this.$actionSheet({ buttons })
        },
        onChangePhoto(event) {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            // if (this.$store.getters.device.app_version < '1.2.5') {
            // const imgFile = event.target.files[0]
            // this.$refs.imageUploader.value = ''
            // this.curPhoto.name = imgFile.name
            // } else {
            this.curPhoto.name = event.fileName
            const imgFile = event.blob
            // }

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return

                    const fReader = new FileReader()
                    fReader.onload = e => {
                        this.curPhoto.url = e.target.result
                        this.curPhoto.blob = croppedFile
                        this.curPhoto.$$deleted = false
                        this.emit()
                    }
                    fReader.readAsDataURL(croppedFile)
                })
        },
        removePhoto() {
            if (this.curPhoto.id) this.deletePhotoIds.push(this.curPhoto.id)

            this.$set(this.curPhoto, 'id', null)
            this.$set(this.curPhoto, 'url', null)
            this.$set(this.curPhoto, '$$deleted', true)
            this.$set(this.curPhoto, 'blob', null)
            this.$set(this.curPhoto, 'name', null)
            this.emit()
        },
        async setMain() {
            if (this.curPhoto.position === 0) return

            if (!this.curPhoto.id) {
                this.$toast.error('심사가 통과되지 않은 사진은 메인으로 지정할 수 없습니다')
                return
            }

            try {
                this.$loading(true)
                await userService.updateMainPhoto(this.curPhoto.id)
                await this.$store.dispatch('loadMe')
                const recentPhotos = this.photos
                const photos = this.$store.getters.me.photos
                this.photos = []
                this.$numArray(6).forEach(idx => {
                    const photo = photos[idx]
                    if (!photo) return
                    const found = recentPhotos.find(p => p.id === photo.id)
                    if (found) this.photos.push(photo || { url: null })
                })

                this.$numArray(6).forEach(idx => {
                    const photo = recentPhotos[idx]
                    if (photo.blob && photo.blob !== null) {
                        this.photos.push(photo || { url: null })
                    }
                })

                this.$numArray(6).forEach(idx => {
                    if (!this.photos[idx]) {
                        this.photos.push({ url: null })
                    }
                })

                this.emit()
            } catch (e) {
                this.$toast.error(e.msg)
            }
            this.$loading(false)
        },
        emit() {
            this.$emit('input', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
            this.photoChanged = true
        },
        onGuideButtonClick() {
            this.$stackRouter.push({
                name: 'ProfileGuidePage',
                props: {
                    from: 'EditPhotos',
                },
            })
        },
    },
    mounted() {
        this.init()
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangePhoto)
    },
    beforeDestroy() {
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangePhoto)
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
.edit-photos {
    margin-top: 32px;
    padding: 0 $edit-profile-padding;

    .photos-desc {
        margin-top: 12px;
        font-size: 12px;
        color: $grey-07;
        text-align: center;
        padding-bottom: 20px;

        @include f-medium;
    }

    button.photos-guide-button {
        all: unset;
        display: flex;
        align-items: flex-end;
        background-color: $grey-01;
        box-shadow: 0 0 0 1px $grey-03 inset;
        border-radius: 18px;
        font-size: 14px;
        margin: 0 auto;
        padding: 9px 33px 10px;

        @include f-medium;

        & > i.material-icons {
            font-size: 17px;
            margin-right: 9px;
        }
    }

    $grid-no: 3;
    $grid-gap: 8px;

    .grid-col {
        $img-size-small: calc((100vw - #{$grid-gap} * (#{$grid-no} - 1) - #{$edit-profile-padding} * 2) / #{$grid-no});
        $img-size-large: calc((#{$img-size-small} * 2) + #{$grid-gap});

        grid-template-columns: repeat($grid-no, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: $grid-gap;

        .photo {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            width: $img-size-small;
            height: $img-size-small;
            background: $grey-01;
            border: solid 1px $grey-02;

            @include center;

            &.required {
                width: $img-size-large;
                height: $img-size-large;

                grid-column: 1 / 3;
                grid-row: 1 / 3;
            }

            &.border {
                border: 1px solid $grey-03;
            }

            .img-cover {
                width: 100%;
                height: 100%;
            }

            .material-icons {
                color: $grey-05;
                font-size: 24px;
            }
        }
    }

    .badges {
        position: absolute;
        left: 8px;
        top: 8px;
        display: flex;

        .badge-required,
        .badge-checking {
            padding: 0 6px;
            font-size: 12px;
            color: white;
            border-radius: 6px;
            height: 20px;

            @include center;
            @include f-medium;
        }

        .badge-required {
            margin-right: 4px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .badge-checking {
            background-color: rgba(255, 61, 107, 0.5);
        }
    }
}
</style>
