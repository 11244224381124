<template>
    <div v-if="me && profile" class="edit-profile">
        <StackRouterHeaderBar
            :leftButtonHandler="backHandler"
            :showTitle="showHeaderTitle"
            :title="$translate('EDIT_PROFILE')"
        />
        <main class="main" :class="this.changed && 'unsaved'" @scroll="onScroll" ref="main">
            <div class="page-header">
                <div class="title" v-html="$translate('EDIT_PROFILE')" />
            </div>

            <EditPhotos v-model="me.photos" @changed="onChange" @onChangeDeletePhotoIds="onChangeDeletePhotoIds" />

            <div class="hr"></div>

            <div>
                <EditIntro :childOf="'profile'" @changed="onChange" v-model="profile.intro" />
                <div class="hr" />
                <EditProfileItems v-model="profile" :sections="sections" :required="required" @changed="onChange" />
            </div>

            <div class="hr-bottom"></div>
            <EditKeywords @changed="onChange" />
        </main>
        <BottomButton v-if="this.changed" @click="onSave" :label="'수정 완료'" />
    </div>
</template>

<script>
import EditPhotos from './components/EditPhotos'
import EditIntro from './components/EditIntro'
import EditProfileItems from './components/EditProfileItems'
import profileService from '@/services/profile'
// import EditProfileTabs from './components/EditProfileTabs'
import EditKeywords from './components/EditKeywords'

export default {
    name: 'EditProfilePage',
    components: {
        EditPhotos,
        EditIntro,
        EditProfileItems,
        // EditProfileTabs,
        EditKeywords,
    },
    data: () => ({
        profile: null,
        changed: false,
        deletePhotoIds: [],
        selectedTab: '',
        showHeaderTitle: false,
    }),
    props: {
        initialTab: String,
        scrollDown: {
            type: Number,
            required: false,
        },
    },
    watch: {
        selectedTab(newTab, oldTab) {
            if (oldTab) {
                const mainDom = this.$refs.main
                mainDom.scrollTop = 0
            }
        },
        '$store.getters.pageStack': {
            handler(newVal) {
                console.log(newVal)
                if (newVal[newVal.length - 1].name === 'EditProfilePage') {
                    this.$registerBackHandler(this.backHandler)
                }
            },
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isTestUser() {
            return [6, 7, 8, 9, 0].includes(this.me.id % 10)
        },
        disabled() {
            if (this.isTestUser) {
                return this.profile.intro.length < 1
            } else {
                return this.profile.intro.length < 30
            }
        },
        requiredKeys() {
            const required = [
                // 'nickname',
                'state',
                'jobCategory',
                'job',
                'school',
                'schoolType',
                'kind',
                'shape',
                'smoking',
                'religion',
                'income',
                'asset',
            ]

            required.push(this.profile.state.is_station ? 'station' : 'region')
            if (this.profile.job_category && this.profile.job_category.verification_required) required.push('company')
            if (this.profile.school_type.verification_required) required.push('university')
            return required
        },
        sections() {
            if (!this.profile) return

            const A = ['state', this.profile.state.is_station ? 'station' : 'region', 'hometown']
            const B1 = ['job', 'jobCategory', 'company', 'income', 'asset']
            const B2 = ['income', 'asset']
            const C = ['school', 'schoolType', 'university']
            const D = ['kind', 'shape', 'smoking', 'religion', 'exercise']
            const E = ['house_style', 'house_payment', 'car_type', 'pet']
            return this.profile.school.is_student ? [A, B2, C, D, E] : [A, B1, C, D, E]
            // }
        },
    },
    methods: {
        async init() {
            this.selectedTab = this.initialTab || 'PHOTO'

            try {
                this.profile = await profileService.me()
                if (this.scrollDown) {
                    this.$nextTick(() => {
                        const dom = document.querySelector('.main')
                        dom.scrollTo({ top: this.scrollDown, behavior: 'smooth' })
                    })
                }
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
            }
        },
        async backHandler() {
            if (!this.changed) {
                this.$stackRouter.pop()
                return false
            }

            const idx = await this.$modal.basic({
                body: '뒤로가기 시 변경 내용이 저장되지 않습니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: '나가기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (!idx) {
                return false
            } else {
                this.$stackRouter.pop()
            }
        },
        async saveProfile() {
            this.missingKeys = this.requiredKeys
                .filter(key => {
                    const fieldName =
                        key === 'university' || key === 'company' ? `${key}_name` : this.$case.toSnake(key)
                    return typeof this.profile[fieldName] !== 'boolean' && !this.profile[fieldName]
                })
                .map(key => this.$translate(this.$case.toConst(key)))

            if (this.missingKeys.length > 0) {
                this.$toast.error(`${this.missingKeys.join(', ')}를 입력하지 않았습니다`)
                return
            }

            try {
                this.$loading(true)
                await profileService.update(this.preparedPayload())
                this.$stackRouter.pop()
            } catch (e) {
                this.$modal.basic({
                    title: e.data.title,
                    body: e.data.msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        required(key) {
            return this.requiredKeys.indexOf(key) !== -1
        },
        userInputAllowed(key) {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(key) !== -1
        },
        onChangeDeletePhotoIds(deletePhotoIds) {
            this.deletePhotoIds = deletePhotoIds
        },
        onChange(val) {
            let value = val
            setTimeout(() => {
                for (let i = 0; i < this.requiredKeys.length; i++) {
                    const snake = this.$case.toSnake(this.requiredKeys[i])
                    if (this.profile[snake] === null) {
                        value = false
                        break
                    }
                }
                this.changed = value
            }, 100)
        },
        preparedPayload() {
            const keys = this.sections.reduce((result, keys) => (result || []).concat(keys))
            const form = new FormData()
            form.append('id', this.profile.id)
            form.append('intro', this.profile.intro || '')
            keys.forEach(camel => {
                if (camel === 'smoking') {
                    form.append('smoking', this.profile.smoking ? '1' : '0')
                    return
                }

                if (['shape', 'religion', 'kind', 'pet'].indexOf(camel) > -1) {
                    return
                }

                if (camel === 'car') {
                    if (typeof this.profile.car === 'boolean') {
                        form.append(camel, this.profile.car)
                    } else if (typeof this.profile.car === 'string' && this.profile.car) {
                        form.append(camel, this.profile.car === '보유' ? '1' : '0')
                    }

                    return
                }

                if (camel === 'jobDescription' && this.profile.job_description) {
                    form.append('job_description', this.profile.job_description)
                    return
                }

                const snake = this.$case.toSnake(camel)
                if (!this.profile[snake]) return

                const prefix = this.userInputAllowed(camel) ? 'name' : 'id'
                form.append(`${snake}_${prefix}`, this.profile[snake][prefix])
            })

            const profileOptionIds = this.profile.profile_options.map(po => po.profile_option_id)
            form.append('profile_option_ids', JSON.stringify(profileOptionIds))

            this.me.photos.filter(p => p.blob).forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))
            form.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))

            return form
        },
        onScroll(event) {
            const scrollTop = event.target.scrollTop

            scrollTop >= 48 ? (this.showHeaderTitle = true) : (this.showHeaderTitle = false)
        },
        onSave() {
            this.saveProfile().then(() => {
                this.$toast.success(this.$translate('SAVED'))
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            })
        },
    },
    mounted() {
        this.$registerBackHandler(this.backHandler)
        this.init()
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
$header-height: 52px;

.edit-profile {
    .header-bar {
        padding: 8px 16px;
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            margin-right: auto;
        }
        .material-icons {
            color: $grey-08;
        }

        .header-title {
            font-size: 16px;
            color: black;
            font-weight: 500;

            @include f-medium;
        }
    }

    .main {
        overflow-y: scroll;
        transition: height 0s;
        height: calc(100vh - #{$header-height});

        &.unsaved {
            transition: height 0s;
            height: calc(100vh - #{$header-height} - 81px);
        }
    }

    .edit-profile-tabs {
        position: sticky;
        top: 0;
        background-color: white;
    }

    .page-header {
        margin-bottom: 16px;

        .title {
            line-height: normal;

            @include f-medium;
        }
    }

    .hr {
        margin: 32px 0;
        height: 1px;
        background: $grey-02;
    }

    .hr-bottom {
        margin: -28px 0 4px 0;
        height: 1px;
        background: $grey-02;
    }

    .edit-intro {
        margin-top: 32px;
    }
}
</style>
