<template>
    <div class="edit-profile-items">
        <h3 class="title" v-html="$translate('BASIC_INFORMATION')" />
        <div :key="idx" v-for="(keys, idx) in sections" class="section">
            <div class="profile-item" :key="key" v-for="key in keys">
                <template>
                    <div class="label" :class="{ required: required(key) }" v-html="$translate($case.toConst(key))" />
                    <div :class="{ 'flex-row': hasChangedNickname && key === 'nickname' }">
                        <div
                            @click="onClickProfileItem(key)"
                            class="btn-selector"
                            :class="{
                                'c-primary': getVal(key) !== 'NO_VALUE',
                                'no-input': getVal(key) === 'NO_VALUE',
                                'changed-nickname': hasChangedNickname && key === 'nickname',
                            }"
                            v-html="$translate(getVal(key))"
                        ></div>
                        <i v-if="hasChangedNickname && key === 'nickname'" class="material-icons lock">lock</i>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditProfileItems',
    props: ['value', 'sections', 'required'],
    data: () => ({
        profile: {},
        profileChanged: false,
    }),
    mounted() {
        this.init()
    },
    computed: {
        subRegion() {
            if ((this.profile.state || {}).is_station) {
                return this.profile.station
                    ? this.$translate('SUB_REGION_DESC').replace(
                          /%s/,
                          this.profile.station.etc || `${this.profile.station.region} ${this.profile.station.district}`
                      )
                    : ''
            }

            return this.profile.region_name
                ? this.$translate('SUB_REGION_DESC').replace(
                      /%s/,
                      `${this.profile.region.name} ${this.profile.region.district}`
                  )
                : ''
        },
        hasChangedNickname() {
            return (this.profile || {}).is_changed_nickname
        },
    },
    methods: {
        init() {
            this.profile = this.value || {}
        },
        getVal(key) {
            if (key === 'smoking') return this.profile.smoking ? 'SMOKING' : 'NON_SMOKING'

            if (
                [
                    'nickname',
                    'kind',
                    'shape',
                    'pet',
                    'religion',
                    'asset',
                    'income',
                    'exercise',
                    'car_type',
                    'house_payment',
                    'house_style',
                ].indexOf(key) >= 0
            ) {
                if (!this.profile[key]) return 'NO_VALUE'

                return this.profile[key]
            }

            if (key === 'car') {
                if (typeof this.profile.car === 'boolean') {
                    return this.profile.car ? '보유' : '미보유'
                }

                if (!this.profile.car) return 'NO_VALUE'

                return this.profile.car
            }

            if (key === 'university') {
                if (!this.profile.university_name) return 'NO_VALUE'

                return this.profile.university_name
            }

            if (key === 'company') {
                if (!this.profile.company_name || this.profile.company_name === 'null') return 'NO_VALUE'

                return this.profile.company_name
            }

            const val = this.profile[this.$case.toSnake(key)]
            if (!val) return 'NO_VALUE'

            return key === 'region' ? val.district : val.name
        },
        onClickProfileItem(key) {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: key,
                        profile: this.profile,
                    },
                })
                .then(item => {
                    if (!item || item.length === 0) {
                        return
                    } else {
                        this.profileChanged = true
                        this.$emit('changed', this.profileChanged)
                    }

                    if (key === 'state' && this.profile.state.name !== item.name) {
                        this.profile.station = null
                        this.profile.region = null
                    }

                    if (key === 'smoking') {
                        this.profile.smoking = item.name === 'SMOKING'
                        return
                    }

                    if (key === 'kind') {
                        this.$set(this.profile, key, item.map(i => i.name).join(', '))
                        this.updateProfileOptions(key, item)
                        return
                    }

                    if (
                        [
                            'shape',
                            'religion',
                            'asset',
                            'income',
                            'exercise',
                            'car_type',
                            'house_payment',
                            'house_style',
                        ].indexOf(key) > -1
                    ) {
                        this.$set(this.profile, key, item.name)
                        this.updateProfileOptions(key, item)
                        return
                    }

                    if (key === 'car') {
                        this.$set(this.profile, key, item.name)
                        return
                    }

                    if (key === 'university') {
                        this.$set(this.profile, 'university_name', item.name)
                    }

                    if (key === 'company') {
                        this.$set(this.profile, 'company_name', item.name)
                    }

                    if (key === 'pet' && item.length > 0) {
                        this.$set(this.profile, key, item.map(i => i.name).join(', '))
                        this.updateProfileOptions(key, item)
                        return
                    }

                    this.$set(this.profile, this.$case.toSnake(key), item)
                    this.$emit('input', this.profile)
                    this.$forceUpdate()
                })
        },
        updateProfileOptions(key, item) {
            const currentProfileOptions = this.profile.profile_options
            if (key === 'kind' || key === 'pet') {
                for (let i = currentProfileOptions.length - 1; i >= 0; i -= 1) {
                    if (currentProfileOptions[i].ptype === key) {
                        currentProfileOptions.splice(i, 1)
                    }
                }

                item.forEach(i => {
                    currentProfileOptions.push({ profile_option_id: i.id, ptype: key })
                })

                this.$set(this.profile, 'profile_options', currentProfileOptions)
            } else {
                const index = currentProfileOptions.findIndex(po => po.ptype === key)

                if (index < 0) {
                    currentProfileOptions.push({ profile_option_id: item.id, ptype: key, name: item.name })
                } else {
                    currentProfileOptions[index].profile_option_id = item.id
                }

                this.$set(this.profile, 'profile_options', currentProfileOptions)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-items {
    .title {
        margin-left: 16px;
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .section {
        padding: 0 20px 32px;

        &:first-child {
            padding-top: 0;
        }

        .profile-item {
            display: flex;

            .label {
                font-size: 16px;
                color: $grey-06;
                width: 128px;
                min-width: 128px;

                &.required::after {
                    content: '*';
                    color: $purple-primary;
                }
            }

            &:not(:last-child) {
                margin-bottom: 28px;
            }

            &:last-child {
                padding-bottom: 28px;
                border-bottom: solid 1px $grey-01;
            }
        }

        &:last-child {
            .profile-item {
                border: 0;
            }
        }
    }

    .sub-region-desc {
        margin-top: 4px;
        font-size: 12px;
        color: $grey-06;
    }

    .btn-selector {
        font-size: 16px;
        color: $purple-primary;

        @include f-medium;

        &.no-input {
            color: $grey-05;
            @include f-regular;
        }

        &.changed-nickname {
            color: $grey-08;
        }
    }

    .lock {
        font-size: 18px !important;
        color: $grey-02;
        margin-left: 8px;
        background-color: $grey-02;
        border-radius: 50%;
        padding: 3px;
    }

    .material-icons {
        font-size: $icon-size;
        color: $grey-05;
    }
}
</style>
